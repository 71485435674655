import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Table, message } from "antd";
import axios from "axios";

const User = () => {
  const [data, setData] = useState([]);
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "_id",
    //   key: "_id",
    // },
    {
      title: "内容",
      dataIndex: "msg",
      key: "msg",
    },
    {
      title: "更新时间",
      dataIndex: "date",
      key: "date",
      render: (num) => new Date(num).toLocaleString(),
    },
    {
      title: "操作",
      dataIndex: "opt",
      key: "opt",
      render: (_, record) => (
        <>
          <Popconfirm
            title="确定删除？"
            onConfirm={(_) => handleDel(record._id)}
          >
            <Button danger type="link">
              删除
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  const handleDel = (id) => {
    axios.post(`https://afoxoh.asia/api/feedbacks/delete`, { id }).then(() => {
      message.success("删除成功");
      handleSearch();
    });
  };
  const handleSearch = () => {
    axios.post("https://afoxoh.asia/api/feedbacks/search").then(({ data }) => {
      console.log(data);
      setData(data);
    });
  };
  useEffect(() => {
    handleSearch();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={data}
        title={() => `共 ${data.length} 条反馈`}
      />
    </div>
  );
};
export default User;
