import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Table, Tag, message } from "antd";
import axios from "axios";
import copy from "copy-to-clipboard";
import { colorList } from "../../utils";

const initPagination = () => ({ page: 1, size: 10 });
const User = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [shopList, setShopList] = useState([]);
  const [pagination, setPagination] = useState(initPagination());
  const handleCopy = (text) => {
    copy(text);
    message.success("复制成功");
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      render: (text) => (
        <div
          style={{
            width: 50,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          onClick={(_) => handleCopy(text)}
        >
          {text}
        </div>
      ),
    },
    {
      title: "搭配者UID",
      dataIndex: "uid",
      key: "uid",
    },
    {
      title: "搭配者昵称",
      dataIndex: "nick",
      key: "nick",
      render: (_, record) => record?.user_list?.[0]?.nick,
    },
    {
      title: "商品",
      dataIndex: "sku_list",
      key: "sku_list",
      render: (lists) =>
        lists.map(({ _id }) => (
          <Tag bordered={false} key={_id}>
            {_id}
          </Tag>
        )),
    },
    {
      title: "所属店铺",
      dataIndex: "shop_type",
      key: "shop_type",
      render: (types) =>
        types.map((type) => (
          <Tag
            bordered={false}
            key={type}
            color={shopList.find((i) => i.value === type)?.color}
          >
            {shopList.find((i) => i.value === type)?.label}
          </Tag>
        )),
    },
    {
      title: "添加数",
      key: "collocation_num",
      dataIndex: "collocation_num",
    },
    {
      title: "更新时间",
      dataIndex: "date",
      key: "date",
      render: (num) => new Date(num).toLocaleString(),
    },
    {
      title: "操作",
      dataIndex: "opt",
      key: "opt",
      render: (_, record) => (
        <>
          <Popconfirm
            title="确定删除？"
            onConfirm={(_) => handleDel(record._id)}
          >
            <Button danger type="link">
              删除
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleDel = (id) => {
    axios
      .post(`https://afoxoh.asia/api/collocations/delete`, { id })
      .then(() => {
        message.success("删除成功");
        handleSearch();
      });
  };

  const handleSearch = () => {
    axios
      .post("https://afoxoh.asia/api/collocations/search", pagination)
      .then(({ data }) => {
        console.log(data);
        setData(data.list);
        setTotal(data.total);
      });
  };

  useEffect(() => {
    axios.post("https://afoxoh.asia/api/shops/search").then(({ data }) => {
      console.log(data);
      setShopList(
        data.map((i, idx) => ({
          label: i.text,
          value: i.type,
          color: colorList?.[idx],
        }))
      );
    });
  }, []);

  useEffect(() => {
    handleSearch();
  }, [pagination]);

  return (
    <div style={{ width: "100%" }}>
      <Table
        rowKey="_id"
        pagination={{
          current: pagination.page,
          pageSize: pagination.size,
          onChange: (page, pageSize) => {
            setPagination({ page, size: pageSize });
          },
          total,
        }}
        columns={columns}
        dataSource={data}
        title={() => `共 ${total} 个搭配方案`}
      />
    </div>
  );
};
export default User;
