import React from "react";
import { RubyOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { getCookie } from "./pages/utils";

const items = [
  {
    key: "many-clothes",
    label: "好多衣服",
    icon: <RubyOutlined />,
    children: [
      {
        key: "g1",
        label: "管理",
        type: "group",
        children: [
          {
            key: "/many-clothes/user",
            disabled: getCookie("uid") !== "o5wBN5ZNu_0sn4VHjdXpq71nHGj4",
            label: "用户管理",
          },
          {
            key: "/many-clothes/collocation",
            disabled: getCookie("uid") !== "o5wBN5ZNu_0sn4VHjdXpq71nHGj4",
            label: "搭配管理",
          },
          {
            key: "/many-clothes/feedback",
            disabled: getCookie("uid") !== "o5wBN5ZNu_0sn4VHjdXpq71nHGj4",
            label: "反馈管理",
          },
        ],
      },
      {
        key: "g2",
        label: "配置",
        type: "group",
        children: [
          {
            key: "/many-clothes/shop",
            disabled: getCookie("uid") !== "o5wBN5ZNu_0sn4VHjdXpq71nHGj4",
            label: "店铺配置",
          },
          {
            key: "/many-clothes/category",
            disabled: getCookie("uid") !== "o5wBN5ZNu_0sn4VHjdXpq71nHGj4",
            label: "分类配置",
          },
          {
            key: "/many-clothes/material",
            label: "素材配置",
          },
        ],
      },
    ],
  },
];
const MyMenu = () => {
  const navigate = useNavigate();
  const onClick = (e) => {
    console.log("click ", e);
    navigate(e.key);
  };
  return (
    <Menu
      onClick={onClick}
      style={{
        width: 256,
        height: "100%",
      }}
      defaultSelectedKeys={["many-clothes"]}
      openKeys={["many-clothes"]}
      selectedKeys={[window.location.pathname]}
      mode="inline"
      items={items}
    />
  );
};
export default MyMenu;
