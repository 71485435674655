import React, { useEffect, useState } from "react";
import { Table } from "antd";
import axios from "axios";

const initPagination = () => ({ page: 1, size: 10 });
const User = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState(initPagination());
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "_id",
    //   key: "_id",
    // },
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
    },
    {
      title: "昵称",
      dataIndex: "nick",
      key: "nick",
    },
    {
      title: "密码",
      dataIndex: "password",
      key: "password",
    },
    {
      title: "收藏数",
      key: "star_list",
      dataIndex: "star_list",
      render: (list) => list?.length || 0,
    },
    {
      title: "已有数",
      key: "have_list",
      dataIndex: "have_list",
      render: (list) => list?.length || 0,
    },
    {
      title: "搭配数",
      key: "collocation_list",
      dataIndex: "collocation_list",
      render: (list) => list?.length || 0,
    },
    {
      title: "更新时间",
      dataIndex: "date",
      key: "date",
      render: (num) => new Date(num).toLocaleString(),
    },
  ];

  const handleSearch = () => {
    axios
      .post("https://afoxoh.asia/api/users/searchAll", pagination)
      .then(({ data }) => {
        setData(data.list);
        setTotal(data.total);
      });
  };

  useEffect(() => {
    handleSearch();
  }, [pagination]);

  return (
    <div style={{ width: "100%" }}>
      <Table
        rowKey="_id"
        pagination={{
          current: pagination.page,
          pageSize: pagination.size,
          onChange: (page, pageSize) => {
            setPagination({ page, size: pageSize });
          },
          total,
        }}
        columns={columns}
        dataSource={data}
        title={() => `共 ${total} 个已登陆用户`}
      />
    </div>
  );
};
export default User;
