import Home from "./pages/Home";
import Material from "./pages/ManyClothes/Material";
import User from "./pages/ManyClothes/User";
import Feedback from "./pages/ManyClothes/Feedback";
import Category from "./pages/ManyClothes/Category";
import Shop from "./pages/ManyClothes/Shop";
import Collocation from "./pages/ManyClothes/Collocation";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { getCookie } from "./pages/utils";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (
      !getCookie("uid") &&
      window.location.href !== "https://www.afoxoh.asia/"
    ) {
      window.location.href = "https://www.afoxoh.asia/";
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />

      {getCookie("uid") && (
        <>
          <Route path="/many-clothes/user" element={<User />} />
          <Route path="/many-clothes/collocation" element={<Collocation />} />
          <Route path="/many-clothes/feedback" element={<Feedback />} />

          <Route path="/many-clothes/shop" element={<Shop />} />
          <Route path="/many-clothes/category" element={<Category />} />
          <Route path="/many-clothes/material" element={<Material />} />
        </>
      )}
    </Routes>
  );
}

export default App;
