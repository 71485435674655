export function getImageSize(imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => reject(new Error("无法加载图片"));
    img.src = imageUrl;
  });
}

export const colorList = [
  "magenta",
  "red",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "blue",
  "geekblue",
  "purple",
];

export const getCookie = (name) => {
  let cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    let parts = cookies[i].split("=");
    if (parts[0] === name) {
      return parts[1];
    }
  }
  return "";
};

// let cookieValue = getCookie('cookie_name'); // 替换'cookie_name'为你的cookie名称
// console.log(cookieValue);

export const setCookie = (name, value, daysToLive) => {
  let cookie = name + "=" + encodeURIComponent(value);
  if (typeof daysToLive === "number") {
    cookie += "; max-age=" + daysToLive * 24 * 60 * 60; // max-age单位是秒
  }
  document.cookie = cookie;
};

// setCookie('cookie_name', 'cookie_value', 7); // 设置cookie名称、值和有效期（7天）

export const deleteCookie = (name) => {
  setCookie(name, "", -1); // 设置为过去的时间即可删除
};

// deleteCookie("cookie_name"); // 删除名为'cookie_name'的cookie
