import { Button, Form, Input, message } from "antd";
import axios from "axios";
import { setCookie } from "../utils";

function Home() {
  const [form] = Form.useForm();

  const handleLogin = () => {
    form.validateFields().then((values) => {
      axios
        .post(`https://afoxoh.asia/api/users/search`, values)
        .then(async ({ data }) => {
          if (data.total) {
            const { uid } = data.list[0];
            setCookie("uid", uid, 14);
            await message.success("登录成功");
            window.location.reload();
          } else {
            message.error("昵称与密码不匹配");
          }
        });
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Form form={form} autoComplete="off" style={{ padding: 10 }}>
        <Form.Item
          label="昵称"
          name="nick"
          rules={[
            {
              required: true,
            },
          ]}
          style={{ width: 300 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="密码"
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
          style={{ width: 300 }}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button style={{ width: 300 }} onClick={handleLogin}>
            点击登陆
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Home;
