import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tag,
  message,
  InputNumber,
  Slider,
  Popconfirm,
  Space,
} from "antd";
import axios from "axios";
import copy from "copy-to-clipboard";
import { colorList, getCookie, getImageSize } from "../../utils";

const initModalConfig = () => ({
  title: "",
  open: false,
  type: "",
});
const initItem = (type = "") => ({
  _id: "",
  type,
  category: "short", // 或许半袖偏多？
  title: "",
  desc: "",
  price: 0,
  url: "",
  width: 100,
  height: 100,
  top: 0,
  left: 0,
  zoom: 0.3,
});
const initSearchParams = () => ({
  type: "rog",
  keyword: "",
});
const initPagination = () => ({ page: 1, size: 10 });
const ADD = "add";
const EDIT = "edit";
const Material = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchParams, setSearchParams] = useState(initSearchParams());
  const [pagination, setPagination] = useState(initPagination());
  const [modalConfig, setModalConfig] = useState(initModalConfig());
  const [categoryList, setCategoryList] = useState([]);
  const [shopList, setShopList] = useState([]);
  const [form] = Form.useForm();
  const url = Form.useWatch("url", form);
  const width = Form.useWatch("width", form);
  const height = Form.useWatch("height", form);
  const top = Form.useWatch("top", form);
  const left = Form.useWatch("left", form);

  const handleAdd = () => {
    setModalConfig({ title: "新增", open: true, type: ADD });
    form.setFieldsValue(initItem(searchParams.type));
  };
  const handleEdit = (item) => {
    setModalConfig({ title: "编辑", open: true, type: EDIT });
    form.setFieldsValue(item);
  };
  const handleSame = (item) => {
    delete item._id;
    item.uid = getCookie("uid");
    axios.post(`https://afoxoh.asia/api/materials/add`, item).then(() => {
      message.success("新增成功");
      handleCancel();
      handleSearch();
    });
  };
  const handleSearch = () => {
    axios
      .post("https://afoxoh.asia/api/materials/search", {
        ...searchParams,
        ...pagination,
      })
      .then(({ data }) => {
        console.log(data);
        setData(data.list);
        setTotal(data.total);
      });
  };
  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log(values);
        if (modalConfig.type === ADD) {
          // 新增
          delete values._id;
          values.uid = getCookie("uid");
          axios
            .post(`https://afoxoh.asia/api/materials/add`, values)
            .then(() => {
              message.success("新增成功");
              handleCancel();
              handleSearch();
            });
        } else if (modalConfig.type === EDIT) {
          // 编辑
          values.uid = getCookie("uid");
          axios
            .post(
              `https://afoxoh.asia/api/materials/edit/${values._id}`,
              values
            )
            .then(() => {
              message.success("编辑成功");
              handleCancel();
              handleSearch();
            });
        }
      })
      .catch(console.log);
  };
  const handleCancel = () => {
    setModalConfig(initModalConfig());
    form.setFieldsValue(initItem());
  };
  const handleDel = (id) => {
    axios.post(`https://afoxoh.asia/api/materials/delete/${id}`).then(() => {
      message.success("删除成功");
      handleSearch();
    });
  };
  const handleCopy = (text) => {
    copy(text);
    message.success("复制成功");
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      render: (text) => (
        <div
          style={{
            width: 50,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          onClick={(_) => handleCopy(text)}
        >
          {text}
        </div>
      ),
    },
    {
      title: "店铺",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <Tag
          bordered={false}
          color={shopList.find((i) => i.value === type)?.color}
        >
          {shopList.find((i) => i.value === type)?.label}
        </Tag>
      ),
    },
    {
      title: "类别",
      dataIndex: "category",
      key: "category",
      render: (category) => (
        <Tag
          bordered={false}
          color={categoryList.find((i) => i.value === category)?.color}
        >
          {categoryList.find((i) => i.value === category)?.label}
        </Tag>
      ),
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={(_) => handleCopy(text)}
        >
          {text}
        </div>
      ),
    },
    {
      title: "描述",
      dataIndex: "desc",
      key: "desc",
      render: (text) => (
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={(_) => handleCopy(text)}
        >
          {text}
        </div>
      ),
    },
    {
      title: "价格",
      dataIndex: "price",
      key: "price",
      render: (text) => (
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={(_) => handleCopy(text)}
        >
          {text}
        </div>
      ),
    },
    {
      title: "图片",
      dataIndex: "url",
      key: "url",
      render: (url, record) => {
        return (
          <div
            style={{
              position: "relative",
              top: 0,
              left: 0,
              overflow: "hidden",
              zoom: record.zoom,
              width: (record.width / 100) * record.oWidth,
              height: (record.height / 100) * record.oHeight,
            }}
          >
            <img
              style={{
                position: "absolute",
                width: record.oWidth,
                height: record.oHeight,
                top: (-record.top / 100) * record.oHeight,
                left: (-record.left / 100) * record.oWidth,
              }}
              alt=""
              src={record.url}
            />
          </div>
        );
      },
    },
    {
      title: "收藏数",
      dataIndex: "star_num",
      key: "star_num",
    },
    {
      title: "已有数",
      dataIndex: "have_num",
      key: "have_num",
    },
    {
      title: "操作人",
      dataIndex: "nick",
      key: "nick",
    },
    {
      title: "更新时间",
      dataIndex: "date",
      key: "date",
      render: (num) => new Date(num).toLocaleString(),
    },
    {
      title: "操作",
      dataIndex: "opt",
      width: 80,
      key: "opt",
      render: (_, record) => (
        <>
          <Button type="link" onClick={(_) => handleEdit(record)}>
            编辑
          </Button>
          <Button type="link" onClick={(_) => handleSame(record)}>
            复制
          </Button>
          <Popconfirm
            title="确定删除？"
            onConfirm={(_) => handleDel(record._id)}
          >
            <Button danger type="link">
              删除
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!url) {
      form.setFieldsValue({ oWidth: 0, oHeight: 0 });
    } else {
      getImageSize(url).then((size) => {
        form.setFieldsValue({ oWidth: size.width, oHeight: size.height });
      });
    }
  }, [form, url]);

  useEffect(() => {
    handleSearch();
  }, [pagination]);

  useEffect(() => {
    axios.post("https://afoxoh.asia/api/categorys/search").then(({ data }) => {
      console.log(data);
      setCategoryList(
        data.map((i, idx) => ({
          label: i.label,
          value: i.value,
          color: colorList?.[idx],
        }))
      );
    });
    axios.post("https://afoxoh.asia/api/shops/search").then(({ data }) => {
      console.log(data);
      setShopList(
        data.map((i, idx) => ({
          label: i.text,
          value: i.type,
          color: colorList?.[idx],
        }))
      );
    });
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.size,
          onChange: (page, pageSize) => {
            setPagination({ page, size: pageSize });
          },
          total,
        }}
        title={() => (
          <Space>
            <Button type="link" onClick={handleAdd}>
              新增
            </Button>
            <Select
              options={shopList}
              value={searchParams.type}
              onChange={async (type) => {
                await setSearchParams((p) => ({ ...p, type }));
                setPagination(initPagination());
              }}
            />
            <Input
              placeholder="请输入关键词"
              value={searchParams.keyword}
              onChange={async (e) => {
                await setSearchParams((p) => ({
                  ...p,
                  keyword: e.target.value,
                }));
                setPagination(initPagination());
              }}
            />
            <span>{`共 ${total} 条数据`}</span>
          </Space>
        )}
      />
      <Modal
        title={modalConfig.title}
        width={600}
        open={modalConfig.open}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <Form form={form} autoComplete="off">
          <Form.Item
            label=""
            name="_id"
            rules={[
              {
                required: false,
              },
            ]}
            hidden
          ></Form.Item>
          <Form.Item
            label="店铺"
            name="type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={shopList} />
          </Form.Item>
          <Form.Item
            label="类别"
            name="category"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={categoryList} />
          </Form.Item>
          <Form.Item
            label="标题"
            name="title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="描述"
            name="desc"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="价格"
            name="price"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="图片地址(alicdn)"
            name="url"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item
              label="原始W"
              name="oWidth"
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber min={0} disabled />
            </Form.Item>
            <Form.Item
              label="原始H"
              name="oHeight"
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber min={0} disabled />
            </Form.Item>
            <Form.Item
              label="缩放倍数"
              name="zoom"
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber min={0} max={1} step="0.01" />
            </Form.Item>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item
              label="T"
              name="top"
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Slider tooltip={(value) => `${value}%`} />
            </Form.Item>
            <Form.Item
              label="L"
              name="left"
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Slider tooltip={(value) => `${value}%`} />
            </Form.Item>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item
              label="W"
              name="width"
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Slider tooltip={(value) => `${value}%`} />
            </Form.Item>
            <Form.Item
              label="H"
              name="height"
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Slider tooltip={(value) => `${value}%`} />
            </Form.Item>
          </div>

          {url && (
            <div style={{ width: 300, position: "relative", margin: "auto" }}>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
              >
                {/** 有时间改成拖动的吧！ */}
                {
                  // 4个点
                  // [
                  //   { top: top + "%", left: left + "%" },
                  //   { top: top + "%", left: left + width + "%" },
                  //   { top: top + height + "%", left: left + "%" },
                  //   { top: top + height + "%", left: left + width + "%" },
                  // ].map((i) => (
                  //   <div
                  //     style={{
                  //       position: "absolute",
                  //       width: 3,
                  //       height: 3,
                  //       background: "red",
                  //       ...i,
                  //     }}
                  //   ></div>
                  // ))
                }
                {
                  // 4根杆
                  [
                    {
                      top: top + "%",
                      left: left + "%",
                      width: width + "%",
                      height: 1,
                    },
                    {
                      top: top + height + "%",
                      left: left + "%",
                      width: width + "%",
                      height: 1,
                    },
                    {
                      top: top + "%",
                      left: left + "%",
                      width: 1,
                      height: height + "%",
                    },
                    {
                      top: top + "%",
                      left: left + width + "%",
                      width: 1,
                      height: height + "%",
                    },
                  ].map((i, idx) => (
                    <div
                      key={idx}
                      style={{
                        position: "absolute",
                        background: "red",
                        ...i,
                      }}
                    ></div>
                  ))
                }
              </div>
              <img
                style={{ width: "100%", border: "1px #000 dashed" }}
                src={url}
                alt={url}
              />
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
};
export default Material;
