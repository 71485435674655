import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Space,
  Table,
  message,
} from "antd";
import axios from "axios";
const initModalConfig = () => ({
  title: "",
  open: false,
  type: "",
});
const ADD = "add";
const EDIT = "edit";
const initItem = () => ({
  _id: "",
  value: "",
  label: "",
  sort: 0,
});
const User = () => {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [modalConfig, setModalConfig] = useState(initModalConfig());
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "_id",
    //   key: "_id",
    // },
    {
      title: "顺序",
      dataIndex: "sort",
      key: "sort",
    },
    {
      title: "字段名",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "字段展示名",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "更新时间",
      dataIndex: "date",
      key: "date",
      render: (num) => new Date(num).toLocaleString(),
    },
    {
      title: "操作",
      dataIndex: "opt",
      key: "opt",
      render: (_, record) => (
        <>
          <Button type="link" onClick={(_) => handleEdit(record)}>
            编辑
          </Button>
          <Popconfirm
            title="确定删除？"
            onConfirm={(_) => handleDel(record._id)}
          >
            <Button danger type="link">
              删除
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  const handleAdd = () => {
    setModalConfig({ title: "新增", open: true, type: ADD });
    form.setFieldsValue(initItem());
  };
  const handleEdit = (item) => {
    setModalConfig({ title: "编辑", open: true, type: EDIT });
    form.setFieldsValue(item);
  };
  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log(values);
        if (modalConfig.type === ADD) {
          // 新增
          delete values._id;
          axios
            .post(`https://afoxoh.asia/api/categorys/add`, values)
            .then(() => {
              message.success("新增成功");
              handleCancel();
              handleSearch();
            });
        } else if (modalConfig.type === EDIT) {
          // 编辑
          axios
            .post(
              `https://afoxoh.asia/api/categorys/edit/${values._id}`,
              values
            )
            .then(() => {
              message.success("编辑成功");
              handleCancel();
              handleSearch();
            });
        }
      })
      .catch(console.log);
  };
  const handleCancel = () => {
    setModalConfig(initModalConfig());
    form.setFieldsValue(initItem());
  };
  const handleDel = (id) => {
    axios.post(`https://afoxoh.asia/api/categorys/delete`, { id }).then(() => {
      message.success("删除成功");
      handleSearch();
    });
  };
  const handleSearch = () => {
    axios.post("https://afoxoh.asia/api/categorys/search").then(({ data }) => {
      console.log(data);
      setData(data);
    });
  };
  useEffect(() => {
    handleSearch();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={data}
        title={() => (
          <Space>
            <Button type="link" onClick={handleAdd}>
              新增
            </Button>
            <span>{`共 ${data.length} 条数据`}</span>
          </Space>
        )}
      />
      <Modal
        title={modalConfig.title}
        width={600}
        open={modalConfig.open}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <Form form={form} autoComplete="off">
          <Form.Item
            label=""
            name="_id"
            rules={[
              {
                required: false,
              },
            ]}
            hidden
          ></Form.Item>
          <Form.Item
            label="顺序"
            name="sort"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="字段名"
            name="value"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="字段展示名"
            name="label"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default User;
